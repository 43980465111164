import { useState, useContext } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "../icons";
import { MENU_LINKS } from "../data";
import { Context } from "../context";

const SubMenu = ({ title, links }) => {
  const [open, setOpen] = useState(false);
  function toggleOpen() {
    setOpen(!open);
  }
  const { theme } = useContext(Context);
  return (
    <div className="sub-menu">
      <style jsx>
        {`
          .sub-menu {
            width: 100%;
          }
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2rem;
            border-bottom: 1px solid ${theme.borderColor};
            margin: 0;
          }
          .header :global(.icon) {
            fill: ${theme.primaryIconColor};
          }
          .title {
            text-align: center;
            color: ${theme.primaryTextColor};
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 0;
          }
          .links {
            display: none;
            flex-direction: column;
            vertical-align: middle;
            margin: 0;
            padding: 1rem 0;
          }
          .links li {
            padding: 0;
            margin: 0;
          }
          .links a {
            text-decoration: none;
            width: 100%;
            display: block;
            font-size: 1.3rem;
            color: ${theme.secondaryTextColor};
            line-height: 2rem;
            padding: 1rem 2rem;
          }
          .links a:hover {
            color: ${theme.primaryTextColor};
          }
          @media screen and (min-width: 800px) {
            .sub-menu :global(.icon) {
              display: none;
            }
            .header {
              border: none;
            }
            .sub-menu {
              max-width: 20rem;
            }
          }
        `}
      </style>
      <style jsx>
        {`
          .links {
            display: ${open ? "flex" : "none"};
            border-bottom: ${open ? `1px solid ${theme.borderColor}` : "none"};
          }
          @media screen and (min-width: 800px) {
            .links {
              display: flex;
            }
          }
        `}
      </style>
      <div className="header" onClick={toggleOpen}>
        <h3 className="title">{title}</h3>
        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      <ul className="links">
        {links.map((link) => (
          <li key={link.url}>
            <a href={link.url}>{link.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Menu = () => {
  const { theme, menu } = useContext(Context);
  const links = MENU_LINKS.map((link) => ({
    ...link,
    url: `/${link.slug}`,
  }));
  const platforms = menu.platforms.map((platform) => ({
    name: platform.name,
    url: `/platform/${platform.slug}`,
  }));
  const categories = menu.categories.map((category) => ({
    name: category.name,
    url: `/category/${category.slug}`,
  }));
  return (
    <div className="menu">
      <style jsx>{`
        .menu {
          position: relative;
          background: ${theme.backgroundColor};
          display: flex;
          flex-direction: column;
          user-select: none;
          border-bottom: 1px solid ${theme.borderColor};
        }
        @media screen and (min-width: 800px) {
          .menu {
            flex-direction: row;
            justify-content: center;
          }
        }
      `}</style>
      <SubMenu key="Platforms" title="Platforms" links={platforms} />
      <SubMenu key="Categories" title="Categories" links={categories} />
      <SubMenu key="More" title="More" links={links} />
    </div>
  );
};

export default Menu;
