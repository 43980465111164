import { useContext } from "react";
import { SquareFacebookIcon, SquareTwitterIcon, SquareYouTubeIcon } from "../icons";
import { Context } from "../context";

const Social = (props) => {
  const { theme } = useContext(Context);
  return (
    <div className="icons">
      <style jsx>{`
        .social {
          display: flex;
          align-items: center;
        }
        .icons :global(.icon) {
          fill: ${theme.secondaryIconColor};
        }
        .icons a {
          margin: 0 0.5rem;
        }
        .icons a:last-child {
          margin-right: 0;
        }
        .icons a:first-child {
          margin-left: 0;
        }
      `}</style>
      <a
        aria-label="Facebook"
        target="_blank"
        rel="noopener"
        href="https://www.facebook.com/gameqik/"
      >
        <SquareFacebookIcon />
      </a>
      <a aria-label="Twitter" target="_blank" rel="noopener" href="https://twitter.com/Game_Qik">
        <SquareTwitterIcon />
      </a>
      <a
        aria-label="YouTube"
        target="_blank"
        rel="noopener"
        href="https://www.youtube.com/channel/UCtvqwkvRKwZfjbCEpqnpOKw"
      >
        <SquareYouTubeIcon />
      </a>
    </div>
  );
};

export default Social;
