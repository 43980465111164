import { useState, useContext, useRef } from "react";

import { Context } from "../context";
import { useLocalStorageState } from "../hooks";
import { apiPost } from "../api";
import { smallFont } from "../styles";

const NewsletterSignup = () => {
  const [signedUp, setSignedUp] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const agreementRef = useRef();
  const { theme } = useContext(Context);

  function signupForNewsletter() {
    if (!email || !/.+\@.+\..+/.test(email)) {
      setError("Invalid email");
      return;
    }
    if (!agreementRef.current.checked) {
      setError("You must agree receive emails");
      return;
    }

    setError("");
    setSignedUp(true);
    apiPost("/newsletter/signup/", { email });
  }
  function handleChange(event) {
    setEmail(event.target.value.trim());
  }
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      signupForNewsletter();
    }
  }

  return (
    <div className="newsletter-signup">
      <style jsx>
        {`
          .newsletter-signup {
            padding: 1rem;
          }
          p {
            font-size: ${smallFont};
            margin: 1rem;
            text-align: center;
            line-height: 125%;
          }
          .email {
            padding: 0.75rem;
            font-size: ${smallFont};
            color: ${theme.secondaryTextColor};
            background: ${theme.backgroundColor};
            border: 1px solid ${theme.borderColor};
            width: 70%;
          }
          button {
            text-align: center;
            background-color: ${theme.primaryButtonBackgroundColor};
            border: none;
            cursor: pointer;
            font-size: ${smallFont};
            color: ${theme.primaryButtonTextColor};
            padding: 0.75rem;
            width: 30%;
          }
          .email-line {
            display: flex;
            justify-content: center;
          }
          .agreement-line {
            display: flex;
            padding: 1rem;
            user-select: none;
            justify-content: center;
            text-align: center;
            align-items: center;
          }
          .check {
            transform: scale(2);
            margin-right: 1rem;
          }
        `}
      </style>
      <p>Tips sent directly to your inbox</p>
      {error && <p>{error}</p>}
      {signedUp ? (
        <p>Thanks for joining!</p>
      ) : (
        <div>
          <div className="email-line">
            <input
              className="email"
              placeholder="Email Address"
              value={email}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <button onClick={signupForNewsletter}>Submit</button>
          </div>
          <label className="agreement-line">
            <input className="check" type="checkbox" ref={agreementRef} />
            <span>Yes I want to receive emails from GameQik.com</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default NewsletterSignup;
