import Head from "next/head";
import BingUET from "./BingUET";
import Hotjar from "./Hotjar";
import GoogleAnalytics from "./GoogleAnalytics";

export default function ({ children }) {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <GoogleAnalytics />
      <Hotjar />
      <BingUET />
      {children}
    </Head>
  );
}
