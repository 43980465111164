import { GOOGLE_ANALYTICS_ID } from "../analytics";

export default function () {
  return (
    <>
    	<script
        dangerouslySetInnerHTML={{
          __html: `
            window.pg=window.pg||[];pg.acq=pg.acq||[];
            pg.acq.push(function() {
              // Google analytics code
              pg.loadJS("https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}");
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-177630334-1');
            });
          `,
        }}
      />
      <script src="//m2d.m2.ai/pg.gameqik.js" async></script>
    </>
  );
}
